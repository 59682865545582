@mixin text($color, $fs, $fw) {
  color: $color;
  font-size: $fs;
  font-weight: $fw;
}

@mixin button($bg, $color, $fs, $boderRa) {
  background-color: $bg;
  color: $color;
  font-size: $fs;
  border-radius: $boderRa;
  -webkit-border-radius: $boderRa;
  -moz-border-radius: $boderRa;
  -ms-border-radius: $boderRa;
  -o-border-radius: $boderRa;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin hover($hover) {
  color: $hover;
}
$xss: 320px;
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1024px;
@mixin MediaQuery($type) {
  @if $type == $xss {
    @media screen and (max-width: $xss) {
      @content;
    }
  } @else if $type == $xs {
    @media screen and (max-width: $xs) {
      @content;
    }
  } @else if $type == $sm {
    @media screen and (max-width: $sm) {
      @content;
    }
  } @else if $type == $md {
    @media screen and (max-width: $md) {
      @content;
    }
  } @else if $type == $lg {
    @media screen and (max-width: $lg) {
      @content;
    }
  }
}
