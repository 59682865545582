.carousel__section {
  margin-top: spacing(6);
  // @include MediaQuery($sm) {
  //   // display: none;
  //   margin-top: spacing(6);
  // }
  position: relative;
  .swiper-wrapper {
    .swiper-slide {
      position: relative;
      cursor: pointer;
      transition: all 1s;
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -ms-transition: all 1s;
      -o-transition: all 1s;
      img {
        width: 100%;
      }

      &:hover {
        .slide--button {
          display: block;
        }
      }

      .slide--button {
        display: none;
        outline: none;
        cursor: pointer;
        position: absolute;
        top: 45%;
        left: 48%;
        @include size(80px, 80px);
        line-height: 80px;
        text-align: center;
        background: rgba(bg("black"), 0.4);
        border: 2px solid text("white");
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        i {
          @include text(text("white"), fs("h2"), fw("fwMedium"));
        }
        @include MediaQuery($sm) {
          top: 30%;
          left: 40%;
        }
      }
    }
  }
  .swiper-tabs {
    text-align: center;
    position: absolute;
    bottom: -6%;
    background-color: bg("primary");
    border-radius: 5px;
    z-index: 100;
    line-height: 64px;
    left: 15%;
    width: 70%;
    box-shadow: 0px 0px 8px 1px bg("line");
    display: flex;
    flex-wrap: wrap;
    @include MediaQuery($sm) {
      position: relative;
      left: 5%;
      width: 90%;
      bottom: -2%;
    }
    .MuiFormControl-root {
      margin-top: 8px;
    }
    .swipter-tabs__link {
      @include button(bg("green"), text("white"), fs("h4"), 5px);
      text-decoration: none;
      text-transform: uppercase;
      outline: none;
      line-height: 55px;
      margin-right: 8px;
      height: 55px;
      width: 100%;
      opacity: 1;
      @include MediaQuery($sm) {
        font-size: fs("subtext1");
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .modal-body {
    iframe {
      width: 100%;
      height: 400px;
    }
  }

  .carousel__reserve {
    @include MediaQuery($sm) {
      //display: none;
    }
    display: flex;
    align-items: center;
    max-width: 940px;
    margin: auto;
    @include size(100%, 80px);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    background-color: bg("primary");
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 2;
    .carousel__reserve--phim {
      display: flex;
      align-items: center;
      width: 100%;
      .dropdown {
        width: 18%;
        margin-left: 2%;
        line-height: 80px;
        .btn {
          @include text(text("primary"), fs("subtext1"), fw("fwMedium"));
        }
        .btn--buy {
          @include button(bg("green"), text("white"), fs("h5"), 5px);
          border: none;
          cursor: pointer;
          padding: spacing(1, 2);
          opacity: 1;
          transition: all 0.3s;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          &:hover {
            opacity: 0.8;
          }
        }
        .dropdown-menu {
          top: 20px;
          will-change: contents !important;
          transform: none !important;
          -webkit-transform: none !important;
          -moz-transform: none !important;
          -ms-transform: none !important;
          -o-transform: none !important;
        }
      }
    }
  }
}
