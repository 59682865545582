.film__section {
  .owl-dots {
    display: none !important;
  }
  padding-top: spacing(8);
  @include MediaQuery($sm) {
    padding-top: spacing(2);
  }
  &--title {
    text-align: center;
    padding-bottom: spacing(1);
    @include MediaQuery($sm) {
      padding-top: spacing(1);
    }
    button {
      border: none;
      cursor: pointer;
      text-decoration: none;
      margin: spacing(2, 2);
      background: transparent;
      outline: none;
      @include text(text("primary"), fs("h3"), fw("fwMedium"));
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
    }
    .active {
      @include text(text("secondary"), fs("h2"), fw("fwMedium"));
    }
    .btn-dangchieu {
      &:hover {
        color: text("secondary");
      }
    }
    .btn-sapchieu {
      &:hover {
        color: text("secondary");
      }
    }
  }
  &--list {
    position: relative;
    .sapChieu {
      display: none;
    }
    .item {
      width: 80%;
      margin: auto;
      @include MediaQuery($sm) {
        width: 90%;
      }
      .item__img {
        position: relative;
        width: 100%;
        height: 320px;

        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        &:hover {
          filter: brightness(60%);
          -webkit-filter: brightness(60%);
        }

        @include MediaQuery($xs) {
          height: 200px;
        }

        img {
          width: 100%;
          height: 320px;
          border-radius: 4px;
          transition: all 0.3s;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;

          @include MediaQuery($xs) {
            height: 200px;
          }
        }
        .item__img--range {
          .item__img--point {
            @include text(text("white"), fs("h4"), fw("fwRegular"));
            background-color: rgba(bg("blue"), 0.8);
            border: 1px solid text("purple");
            border-radius: 4px;
            padding: spacing(0.2);
            position: absolute;
            top: 12px;
            right: 12px;
            width: 54px;
            text-align: center;
            line-height: 1.1;
            p:first-child {
              margin: 0;
              @include text(text("white"), fs("h4"), fw("fwBold"));
              @include MediaQuery($sm) {
                font-size: fs("h5");
              }
            }
            p:last-child {
              width: 50px;
              margin: 0;
              @include MediaQuery($sm) {
                width: 40px;
              }
              i {
                @include text(text("secondary"), fs("subtext3"), fw("fwLight"));
              }
            }
          }
        }
        .img--play {
          @include size(60px, 60px);
          outline: none;
          cursor: pointer;
          @include button(rgba(bg("black"), 0.5), text("white"), fs("h3"), 50%);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;
          border: 1px solid text("white");
          display: none;
          transition: all 0.3s;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          &:hover {
            background: transparent;
          }
        }
      }
      .item__title {
        padding-top: spacing(0.8);
        position: relative;
        height: 100px;
        @include MediaQuery($sm) {
          height: 50px;
        }
        .showHover {
          display: none;
          cursor: pointer;
          .showHover--btn {
            outline: none;
            cursor: pointer;
            position: absolute;
            top: 10px;
            left: 0;
            width: 100%;
            padding: spacing(1);
            text-align: center;
            border: none;
            @include button(bg("orange1"), text("white"), fs("h4"), 4px);
            background: linear-gradient(to left, bg("orange1"), bg("orange2"));
            a {
              text-decoration: none;
              @include text(text("white"), fs("h4"), fw("fwMedium"));
            }
            &:hover {
              background: bg("orange2");
            }
          }
        }
        .item__title--age {
          @include text(text("white"), fs("h5"), fw("fwMedium"));
          background-color: bg("orange1");
          border-radius: 4px;
          padding: spacing(0, 0.5);
          min-width: 33px;
          text-align: center;
          display: inline-block;
          margin-right: spacing(0.8);
          @include MediaQuery($xs) {
            font-size: fs("h5")-2;
          }
        }
        .item__title--name {
          @include text(text("primary"), fs("h5"), fw("fwMedium"));
          @include MediaQuery($xs) {
            font-size: fs("h5")-2;
          }
        }
        .item__title--time {
          @include text(text("green"), fs("h5"), fw("fwLight"));
          margin-top: spacing(0.8);
          display: block;
        }
      }
      &:hover {
        .item__img {
          img {
            filter: brightness(20%);
            -webkit-filter: brightness(20%);
          }

          .img--play {
            display: block;
          }
        }

        .item__title {
          .showHover {
            display: block;
          }
          .item__title--age {
            display: none;
          }
          .item__title--name {
            display: none;
          }
          .item__title--time {
            display: none;
          }
        }
      }
    }
  }
}
