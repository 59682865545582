.menuAdmin {
  @include MediaQuery($sm) {
    display: none;
  }
  width: 18%;
  background-color: bg("primary");
  .menuAdmin__info {
    display: flex;
    padding: spacing(0.5);
    border-bottom: 1px solid text("gray1");
    .menuAmin__info--img {
      width: 25%;
      img {
        width: 90%;
      }
    }
    .menuAdmin__info--name {
      margin-top: 5%;
      p {
        @include text(text("primary"), fs("h3"), fw("fwRegular"));
        margin: 0;
      }
      p:last-child {
        @include text(text("gray1"), fs("subtext2"), fw("fwRegular"));
        span {
          width: 10px;
          height: 10px;
          display: inline-block;
          border-radius: 9999px;
          margin-right: 5px;
          background-color: text("yellow");
        }
      }
    }
  }
  .menuAdmin__search {
    width: 96%;
    padding-top: 5%;
    margin-left: 2%;
  }
  .menuAdmin__nav {
    margin-top: 10%;
    ul {
      padding: 0;
      li {
        cursor: pointer;
        opacity: 1;
        margin: spacing(0.5, 0);

        background: bg("blue1");
        list-style: none;
        transition: all 0.3s;
        .menuAdmin__link {
          margin-left: 5%;
          display: inline-block;
          width: 100%;
          padding: spacing(1);
          text-decoration: none;
          @include text(text("white"), fs("h4"), fw("fwLight"));
          i {
            margin-right: 5px;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.menuAdminMobile {
  width: 95%;

  background-color: bg("primary");
  .menuAdmin__info {
    display: flex;
    padding: spacing(0.5);
    border-bottom: 1px solid text("gray1");
    .menuAmin__info--img {
      width: 25%;
      img {
        width: 90%;
      }
    }
    .menuAdmin__info--name {
      margin-top: 5%;
      p {
        @include text(text("primary"), fs("h3"), fw("fwRegular"));
        margin: 0;
      }
      p:last-child {
        @include text(text("gray1"), fs("subtext2"), fw("fwRegular"));
        span {
          width: 10px;
          height: 10px;
          display: inline-block;
          border-radius: 9999px;
          margin-right: 5px;
          background-color: text("yellow");
        }
      }
    }
  }

  .menuAdmin__nav {
    margin-top: 10%;
    ul {
      padding: 0;
      li {
        cursor: pointer;
        opacity: 1;
        margin: spacing(0.5, 0);

        background: bg("blue1");
        list-style: none;
        transition: all 0.3s;
        .menuAdmin__link {
          margin-left: 5%;
          display: inline-block;
          width: 100%;
          padding: spacing(1);
          text-decoration: none;
          @include text(text("white"), fs("h4"), fw("fwLight"));
          i {
            margin-right: 5px;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
