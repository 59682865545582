* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
.container {
  max-width: 1100px;
  margin: auto;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 4px;
  @mixin MediaQuery($sm) {
    width: 2px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fb4226;
  border-radius: 10px;
  @mixin MediaQuery($sm) {
    width: 2px;
  }
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ce3017;
}

// Loading
.loader,
.loader:before,
.loader:after {
  background: bg("orange1");
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 3em;
}
.loader {
  color: bg("orange1");
  text-indent: -9999em;
  margin: 300px auto;
  position: relative;
  font-size: 28px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 4em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 3em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 4em;
  }
}
