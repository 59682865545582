//Bg
$bgcolor: (
  "primary": rgb(255, 255, 255),
  "black": #222222,
  "orange1": #fb4226,
  "orange2": #ce3017,
  "blue": #1f2e46,
  "blue1": #4a90e2,
  "green": #4a4a4a,
  "gray1": #ebebec,
  "gray2": #e9e8e8,
  "gray3": #afafaf,
  "white1": #fbfbfb,
  "white2": #f5f5f5,
  "white3": #f0f0f0,
  "white4": #f1f4f7,
  "linear-gradient": rgb(10, 32, 41),
  "line": #3d3d3d,
);

//Text color
$textColor: (
  "primary": #000,
  "secondary": #fb4226,
  "green": #00ac4d,
  "white": #fff,
  "gray": #c5c5c5,
  "gray1": #9b9b9b,
  "purple": #1f2e46,
  "green1": #3e515d,
  "yellow": #f7b500,
  "blue": #4a90e2,
  "blue1": #30a5ff,
  "light": #e9e9e9,
  "black1": #333,
);

//Font size
$fs: (
  "h0": 36px,
  "h1": 32px,
  "h2": 24px,
  "h3": 20px,
  "h4": 16px,
  "h5": 14px,
  "subtext1": 13px,
  "subtext2": 12px,
  "subtext3": 8px,
  "number": 50px,
);

//Font weight
$fw: (
  "fwBold": 700,
  "fwMedium": 600,
  "fwRegular": 500,
  "fwLight": 300,
);
// hover
$hover: (
  "white": #fff,
  "light": #ece3e3,
  "red": red,
);

//SPACING
$spacing: 10px;
