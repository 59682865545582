.btn_totop {
  /* text-decoration: none; */
  cursor: pointer;
  position: fixed;
  bottom: 24px;
  right: 1%;
  overflow: hidden;
  z-index: 999;
  width: 64px;
  height: 64px;
  border: none;
  text-indent: 100%;
  background: url(../images/arrowup.png) no-repeat 0px 0px;
}
